@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --background-color-light: #efe4d7;
  --background-color-dark: #5b5b5b;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--background-color-light);
  margin: 0;
  font-family: 'Poppins';
}

.navbar {
  background: var(--background-color-dark);
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 999;
  display: flex;
}

.navbar .mobile-navigation {
  display: none;
}

.nav-name {
  font-size: 2rem;
  color: var(--background-color-light);
}

.navbar nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar nav ul li {
  display: inline-block;
  margin: 0 50px 0 0;
  padding-bottom: 25px;
}

.navbar nav ul li a {
  text-decoration: none;
  color: var(--background-color-light);
}

@media (max-width: 990px) {
  .navbar .navigation {
    display: none;
  }
  .navbar .mobile-navigation {
    display: flex;
    align-items: center;
  }

  .navbar .mobile-navigation .hamburger {
    position: absolute;
    right: 5%;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .navbar nav ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 0;
    width: 20%;
    text-align: center;
    background-color: var(--background-color-dark);
  }
  .navbar nav ul li {
    border-top: 2px solid var(--background-color-light);
  }
}

.main-section {
  background-color: var(--background-color-light);
  background-image: url('./local_files/Frame-1.png');
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 95vh;
  .main-text {
    font-size: calc(16px + 6 * (100vw / 680));
    width: 50vw;
    padding-left: 5vw;
    padding-top: 20vh;
    text-align: center;
  }
}

.aboutme-section {
  background-color: var(--background-color-light);
  display: flex;
  flex-direction: row;
  padding-top: 3vh;
  padding-bottom: 3vh;
  width: 100vw;
  h1 {
    font-size: calc(3vw + 2px);
  }
  .aboutme-text {
    text-align: left;
    padding-right: 1vw;
    padding-top: 4%;
    font-size: calc(1vw + 6px);
  }

  .aboutme-portrait {
    object-fit: contain;
    width: 100%;
    max-width: 40vw;
    padding-right: 4vw;
    padding-left: 2vw;
  }
}

.skillset-section {
  background: var(--background-color-dark);
  width: 100vw;
  padding-bottom: 1vh;
  .skillset-text {
    color: var(--background-color-light);
    text-align: center;
    padding-top: 3%;
    font-size: calc(1vw + 10px);
    .credentials {
      font-weight: bold;
      font-size: 2vw;
    }
  }

  .skillset-icons-div-1 {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }

  .skillset-icons-div-2 {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }

  .skillset-icon-box {
    padding-top: 1vh;
    height: calc(10vh + 30px);
    width: calc(10vw + 30px);
    margin: 1.6vw;
    background: #727272;
    border: 1px solid #5f5f5f;
    box-shadow: 0px 1px 21px 9px rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    .skillset-icon-text {
      color: var(--background-color-light);
      font-size: calc(0.75vw + 5px);
    }
  }

  .skillset-icon {
    object-fit: contain;
    width: 75%;
    height: 75%;
  }
}

.project-section {
  background-color: var(--background-color-light);
  padding-top: 5vh;
  padding-bottom: 3vh;
  text-align: center;
  .carousel-caption {
    padding-top: -30%;
    color: '#5b5b5b';
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 100px;
    width: 100px;
    padding-top: 40vh;
    background-size: 100%, 100%;
    border-radius: 50%;
    background-image: none;
  }

  .carousel-control-next-icon:after {
    content: '>';
    font-size: 55px;
    color: black;
  }

  .carousel-control-prev-icon:after {
    content: '<';
    font-size: 55px;
    color: black;
  }
}

.contactme-content {
  padding-left: 11vw;
  .contactme-icon {
    margin: 0 1vw 3vh 1vw;
    width: 6vw;
  }
  .vl {
    border-left: 1px solid black;
    height: 50vh;
  }
  .contactme-text {
    font-size: calc(1vw + 8px);
    text-align: center;
  }
  .column {
    float: left;
    padding-right: 3vw;
  }

  .contactme-section:after {
    content: '';
    display: table;
    clear: both;
  }

  @media screen and (max-width: 1000px) {
    .column {
      width: 100%;
    }
    .contactme-resume {
      margin-left: -12vw;
    }
    .pdf {
      max-width: 101vw;
      max-height: 50vh;
    }
  }
  @media screen and (max-width: 1550px) {
    .vl {
      display: none;
    }
  }
}
